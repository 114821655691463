declare global {
  interface Array<T> {
    unique(key: keyof T, key2?: string): Array<T>;
    getMin(key: keyof T): T | null;
    sortBy(key: keyof T, direction?: 'desc' | 'asc'): Array<T>;
  }
}

Array.prototype.unique = function <T>(key: keyof T, key2?: string): Array<T> {
  const verified = new Set();
  return this.filter((item) => {
    if (!item[key]) {
      return false;
    }

    const toEvaluate = key2 ? item[key][key2] : item[key];
    if (!toEvaluate) {
      return false;
    }

    const isDuplicate = verified.has(toEvaluate);
    if (!isDuplicate) {
      verified.add(toEvaluate);
    }
    return !isDuplicate;
  });
};

Array.prototype.getMin = function <T>(key: string): T | null {
  const checker = (o: Record<string, unknown>, i: string) => typeof o === 'object' && o[i];
  return (
    (this.length &&
      this.reduce(function (prev, curr) {
        const prevOk = checker(prev, key);
        const currOk = checker(curr, key);
        if (!prevOk && !currOk) return {};
        if (!prevOk) return curr;
        if (!currOk) return prev;
        return prev[key] < curr[key] ? prev : curr;
      })) ||
    null
  );
};

Array.prototype.sortBy = function <T>(key: string, direction: 'desc' | 'asc' = 'asc'): Array<T> {
  return this.sort((a: T, b: T) => {
    return direction == 'asc' ? a[key] - b[key] : b[key] - a[key];
  });
};

export {};
