import { yupResolver } from '@hookform/resolvers/yup';
import { ApiError, REGISTRATION_EMAIL_EXISTS, REGISTRATION_VAT_EXISTS } from '@jupiter/shared';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { SerializedError } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import AppTitle from '../../components/app-title';
import FormCheckbox from '../../components/forms/form-checkbox';
import FormPhoneCode from '../../components/forms/form-phone-code';
import FromSelect, { FormSelectItem } from '../../components/forms/form-select';
import FormTextField from '../../components/forms/form-text-field';
import LoadingButton from '../../components/forms/loading-button';
import VBox from '../../components/layout/vbox';
import Hyperlink from '../../components/navigation/hyperlink';
import useNavigation from '../../navigation/nav.hook';
import { LINK_TERMS, ROUTE_LOGIN } from '../../navigation/routes';
import { useRegisterMutation } from '../../redux/authentication/authentication.api';
import useAuthentication from '../../redux/authentication/authentication.hooks';
import useNotifications from '../../redux/notifications/notifications.hooks';
import { useGetPlansQuery } from '../../redux/plans/plans.api';
import { useLazyGetPromotionByIdQuery } from '../../redux/promotions/promotions.api';
import { Promotion } from '../../redux/promotions/promotions.interface';
import { Plan } from '../../shared/dtos/user.interface';
import { passwordRegex } from '../../shared/dtos/validators/password.validator';
import { phoneNumberRegex } from '../../shared/dtos/validators/phone.validator';
import { validateVat } from '../../shared/dtos/validators/vat.validator';
import { countryCodes } from '../../shared/helpers/phone.helpers';
import { RegistrationForm } from './registration.interfaces';

const useStyles = makeStyles(() => ({
  grid: {
    height: '100%',
  },
}));

function RegistrationError({ error }: { error?: SerializedError | FetchBaseQueryError | undefined }) {
  const { t } = useTranslation('common');

  if (!error) return null;

  let message = 'Ocorreu um erro inesperado';
  if ('data' in error) {
    const data = error.data as ApiError;

    if (data.type === REGISTRATION_EMAIL_EXISTS) {
      message = t('registration-page.error-email-exists');
    } else if (data.type === REGISTRATION_VAT_EXISTS) {
      message = t('registration-page.error-vat-number-exists');
    }
  }

  return (
    <Typography variant="body1" component="p" color="error">
      {message}
    </Typography>
  );
}

function getPlanItem(plan: Plan, promotion: Promotion | undefined) {
  const label = plan.trialDays ? (
    <Trans
      i18nKey="registration-page.label-plan-name-with-trial"
      values={{ name: plan.name, days: promotion?.trialDays ?? plan.trialDays }}
    />
  ) : (
    `${plan.name}`
  );
  return { label, value: plan.id };
}

export default function RegistrationPage() {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const methods = useForm<RegistrationForm>({
    resolver: yupResolver(
      yup
        .object({
          planId: yup.number().required(),
          email: yup.string().email().required(),
          password: yup.string().matches(passwordRegex, t('common.error-invalid-password')).required(),
          passwordConfirm: yup.string().required(),
          name: yup.string().min(3).required(),
          vat: yup
            .number()
            .required()
            .test('is-valid-vat', t('common.error-invalid-nif'), (value) => validateVat(value)),
          phoneCountryCode: yup
            .string()
            .transform((value) => value.phone)
            .oneOf(countryCodes, t('common.error-invalid-area-code'))
            .required(),
          phone: yup.string().required().matches(phoneNumberRegex, t('common.error-invalid-phone-number')),
          termsAccepted: yup.boolean().oneOf([true], t('registration-page.error-terms-and-conditions')).required(),
          improvementsCheck: yup.boolean().default(false).optional(),
          marketingCheck: yup.boolean().default(false).optional(),
          thirdPartyMarketingCheck: yup.boolean().default(false).optional(),
        })
        .required(),
    ),
  });
  const { isLoggedIn } = useAuthentication();
  const { navToHome, navToLogin } = useNavigation();
  const [register, { isLoading, error }] = useRegisterMutation();
  const { show } = useNotifications();
  const { data: plans, isLoading: plansLoading } = useGetPlansQuery();
  const [getPromotionById, { data: promotion }] = useLazyGetPromotionByIdQuery();
  const [searchParams] = useSearchParams();

  const items = useMemo<FormSelectItem[]>(() => {
    if (promotion?.plan) {
      return [getPlanItem(promotion.plan, promotion)];
    }

    return (
      plans?.map((plan) => {
        return getPlanItem(plan, promotion);
      }) ?? []
    );
  }, [plans, promotion]);

  useEffect(() => {
    if (isLoggedIn) {
      navToHome();
    }
  }, [isLoggedIn, navToHome]);

  useEffect(() => {
    const qPlanId = searchParams.get('planId');
    const plan = items?.find((item) => item.value === Number(qPlanId));
    const planToSet = plan?.value ?? items?.[0]?.value ?? 0;
    methods.setValue('planId', Number(planToSet));

    const promotionId = searchParams.get('promo');
    if (promotionId) {
      getPromotionById(promotionId);
    }
  }, [items, methods, searchParams, getPromotionById]);

  const onSubmit = async (data: RegistrationForm) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { passwordConfirm, termsAccepted, ...request } = data;

    if (promotion) {
      request.promotionId = promotion.id;
    }

    try {
      await register(request).unwrap();
      show({
        message: t('registration-page.success-registration'),
        severity: 'info',
        duration: 30000,
      });
      navToLogin();
    } catch (e) {
      // Error already handled in the selector
    }
  };

  return (
    <Box
      width={{ xs: '100%', sm: '100%', md: 600 }}
      height={{ xs: '100%', sm: '100%', md: 'auto' }}
      mx="auto"
      my={{ xs: 'auto', sm: 'auto', md: 0 }}
      paddingTop={{ xs: '28px', sm: '28px', md: '10vh' }}
    >
      <VBox spacing={0} className={classes.grid}>
        <VBox spacing={2}>
          <Box display="flex" justifyContent="center">
            <Box borderRadius={26} paddingX={2.5} paddingY={1.5} bgcolor="secondary.main" boxShadow={1}>
              <AppTitle />
            </Box>
          </Box>
          <Box textAlign="center" padding="20px">
            <Typography variant="h6">{t('registration-page.title')}</Typography>
          </Box>
        </VBox>
        <Box
          height="100%"
          paddingY={2.5}
          paddingX={4.5}
          borderRadius={{ xs: '20px 20px 0 0', sm: '20px 20px 0 0', md: '20px 20px 20px 20px' }}
          boxShadow={{ xs: 1, sm: 1, md: 0 }}
          bgcolor="secondary.main"
        >
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <VBox spacing={2}>
                <FromSelect disabled={plansLoading} name="planId" items={items.reverse()} defaultValue={''} fullWidth />
                <FormTextField
                  name="email"
                  type="text"
                  label={t('common.label-email')}
                  disabled={plansLoading}
                  fullWidth
                />
                <FormTextField
                  name="password"
                  type="password"
                  label={t('common.label-password')}
                  disabled={plansLoading}
                  fullWidth
                />
                <FormTextField
                  name="passwordConfirm"
                  type="password"
                  label={t('common.label-password-confirmation')}
                  disabled={plansLoading}
                  fullWidth
                />
                <FormTextField name="name" type="text" label={t('common.label-name')} fullWidth />
                <FormTextField name="vat" label={t('common.label-nif')} disabled={plansLoading} fullWidth />
                <Grid container spacing={2} alignItems="flex-end">
                  <Grid item xs={3}>
                    <FormPhoneCode
                      name="phoneCountryCode"
                      label={t('common.label-area-code')}
                      disabled={plansLoading}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <FormTextField
                      name="phone"
                      label={t('common.label-phone-number')}
                      disabled={plansLoading}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Box textAlign="left" paddingX={3}>
                  <FormCheckbox
                    name="termsAccepted"
                    label={
                      <span>
                        <Trans i18nKey="registration-page.label-terms-and-conditions">
                          <Link target="_blank" rel="noopener noreferrer" href={LINK_TERMS} />
                        </Trans>
                      </span>
                    }
                    disabled={plansLoading}
                  />
                  <FormCheckbox
                    name="improvementsCheck"
                    label={t('registration-page.label-product-communications')}
                    disabled={plansLoading}
                  />
                  <FormCheckbox
                    name="marketingCheck"
                    label={t('registration-page.label-marketing-communications')}
                    disabled={plansLoading}
                  />
                  <FormCheckbox
                    name="thirdPartyMarketingCheck"
                    label={t('registration-page.label-partner-communications')}
                    disabled={plansLoading}
                  />
                </Box>

                <Box textAlign="center">
                  <RegistrationError error={error} />
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    isLoading={methods.formState.isSubmitting || isLoading}
                    disabled={plansLoading}
                  >
                    {t('common.button-register')}
                  </LoadingButton>
                </Box>
              </VBox>
            </form>
          </FormProvider>
          <Box marginTop={4.25} textAlign="center">
            <Typography variant="body2" component="p" color="textPrimary">
              <Trans i18nKey="registration-page.label-login-link">
                <Hyperlink path={ROUTE_LOGIN} />
              </Trans>
            </Typography>
          </Box>
        </Box>
      </VBox>
    </Box>
  );
}
