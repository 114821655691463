import { setMonth } from 'date-fns';
import { capitalize as capitalizeString } from './string.helpers';

export function isExpired(date: Date) {
  return new Date(date) < new Date();
}

export function toMonthName(
  monthOrDate: Date | number | string,
  options: { locale?: string; capitalize?: boolean },
): string {
  const { locale, capitalize } = options;
  const date =
    typeof monthOrDate === 'number'
      ? setMonth(new Date(), monthOrDate)
      : typeof monthOrDate === 'string'
        ? new Date(monthOrDate)
        : monthOrDate;

  const monthName = date.toLocaleString(locale, { month: 'long' });
  if (capitalize) {
    return capitalizeString(monthName);
  }

  return monthName;
}
