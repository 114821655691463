import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import LanguageIcon from '@material-ui/icons/Language';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface LanguageSelectProps {
  disabled?: boolean;
}

export default function LanguageSelect(props: LanguageSelectProps) {
  const { disabled } = props;
  const { i18n } = useTranslation('common');
  const [anchorEl, setAnchorEl] = useState(null);

  const options = [
    {
      shortLabel: 'PT',
      label: 'Português (PT)',
      value: 'pt-PT',
    },
    {
      shortLabel: 'EN',
      label: 'English (EN)',
      value: 'en',
    },
  ];

  const findShortLabel = (): string | undefined => {
    const option = options.find(({ value }) => value === i18n.language);
    return option?.shortLabel;
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (value: string) => {
    if (value && typeof value === 'string') {
      i18n.changeLanguage(value);
    }

    setAnchorEl(null);
  };

  return (
    <Box>
      <Button
        onClick={handleClick}
        disabled={disabled}
        variant="outlined"
        startIcon={<LanguageIcon />}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {findShortLabel()}
      </Button>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {options.map(({ label, value }) => (
          <MenuItem key={value} value={value} onClick={() => handleChange(value)}>
            {label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
