export const REGISTRATION_EMAIL_EXISTS = '/registration/email-exists';
export const REGISTRATION_VAT_EXISTS = '/registration/vat-exists';
export const USER_NOT_PAID = '/user/not-paid';
export const USER_ACCOUNT_CLOSED = '/user/closed';
export const AUTH_UNAUTHORIZED = '/auth/unauthorized';
export const AUTH_BLOCKED = '/auth/blocked';
export const AUTH_DELETED = '/auth/deleted';
export const AUTH_NOT_VERIFIED = '/auth/not-verified';
export const BILLING_UNAVAILABLE = '/billing/unavailable';
export const PAYMENT_METHOD_UNAVAILABLE = '/payment/unavailable';
export const PAYMENT_METHOD_NOT_FOUND = '/payment/not-found';
export const PORTAL_LOGIN_FAILED = '/portal/login-failed';
export const ASSISTANT_THROTTLED = '/assistant/throttled';
export const ASSISTANT_UNAVAILABLE = '/assistant/unavailable';
export const EXPENSE_ALREADY_EXISTS = '/expense/already-exists';
export const EXPENSE_NOT_FOUND = '/expense/not-found';
export const ENTITY_NOT_FOUND = '/entity/not-found';

export interface ApiError {
  type: string;
  message: string;
}

export const errors = {
  authUnauthorized: { type: AUTH_UNAUTHORIZED, message: 'Invalid credentials' },
  authBlocked: { type: AUTH_BLOCKED, message: 'Account is locked' },
  authDeleted: { type: AUTH_DELETED, message: 'Account has been deleted' },
  authNotVerified: { type: AUTH_NOT_VERIFIED, message: 'Account not verified' },
  userNotPaid: { type: USER_NOT_PAID, message: 'Subscription was not successfully billed' },
  userAccountClosed: { type: USER_ACCOUNT_CLOSED, message: 'Account was closed due to billing failure' },
  registrationEmailExists: { type: REGISTRATION_EMAIL_EXISTS, message: 'Email already exists' },
  registrationVatExists: { type: REGISTRATION_VAT_EXISTS, message: 'VAT already exists' },
  billingUnavailable: { type: BILLING_UNAVAILABLE, message: 'Billing is unavailable for user type' },
  paymentMethodUnavailable: { type: PAYMENT_METHOD_UNAVAILABLE, message: 'Payment method is unavailable' },
  paymentMethodNotFound: { type: PAYMENT_METHOD_NOT_FOUND, message: 'Payment method was not found' },
  portalLoginFailed: { type: PORTAL_LOGIN_FAILED, message: 'Portal login failed' },
  expenseAlreadyExists: { type: EXPENSE_ALREADY_EXISTS, message: 'Expense already exists' },
  expenseNotFound: { type: EXPENSE_NOT_FOUND, message: 'Expense not found' },
  entityNotFound: { type: ENTITY_NOT_FOUND, message: 'Entity not found' },
};
