import { TaxesSimulationRequest, TaxesSimulationResponse } from '@jupiter/shared';

import { api } from '../api';

export const simulatorsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    simulateTaxes: builder.mutation<TaxesSimulationResponse, TaxesSimulationRequest>({
      query: (body) => ({
        url: 'simulators/taxes/simulate',
        method: 'POST',
        body,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useSimulateTaxesMutation } = simulatorsApi;
