import { NavItem, NavSection } from './nav.interfaces';
import {
  assistantRoute,
  blogLink,
  businessDetailsRoute,
  calendarRoute,
  dashboardRoute,
  employmentDetailsRoute,
  expensesRoute,
  integrationsRoute,
  invoicesRoute,
  legalLink,
  newInvoiceRoute,
  personalDetailsRoute,
  simulatorsRoute,
  taxesIrsRoute,
  taxesSocialSecurityRoute,
  taxesVatRoute,
} from './routes';

import AccessibilityIcon from '@material-ui/icons/Accessibility';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import BusinessIcon from '@material-ui/icons/Business';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DescriptionIcon from '@material-ui/icons/Description';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import MenuBookOutlinedIcon from '@material-ui/icons/MenuBookOutlined';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PersonIcon from '@material-ui/icons/Person';
import ReceiptIcon from '@material-ui/icons/Receipt';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import WorkIcon from '@material-ui/icons/Work';
import { useTranslation } from 'react-i18next';
import { FeatureType } from '../common/helpers/billing.helpers';
import { AnimatedBotIcon } from '../components/lottie-icon/icons/animated-bot-icon';
import { useGetProfileQuery } from '../redux/authentication/authentication.api';
import NavAppTitle from './components/nav-app-title';
import NavListItem from './components/nav-list-item';
import NavListSection from './components/nav-list-section';
import NavUser from './components/nav-user';

const createSidebar = (features: FeatureType[]): NavSection[] => {
  const controlPanel: NavItem[] = [];
  const taxDetailsPanel: NavItem[] = [];
  const invoicePanel: NavItem[] = [];
  const taxesPanel: NavItem[] = [];
  const simulatorsPanel: NavItem[] = [];

  taxDetailsPanel.push({
    title: personalDetailsRoute.title,
    path: personalDetailsRoute.path,
    icon: <PersonIcon />,
  });

  taxDetailsPanel.push({
    title: businessDetailsRoute.title,
    path: businessDetailsRoute.path,
    icon: <BusinessIcon />,
  });

  taxDetailsPanel.push({
    title: employmentDetailsRoute.title,
    path: employmentDetailsRoute.path,
    icon: <WorkIcon />,
  });

  invoicePanel.push({
    title: invoicesRoute.title,
    path: invoicesRoute.path,
    icon: <AssignmentOutlinedIcon />,
  });

  invoicePanel.push({
    title: newInvoiceRoute.title,
    path: newInvoiceRoute.path,
    icon: <AddCircleOutlineOutlinedIcon />,
    action: 'create',
    subject: 'Invoice',
  });

  if (features.includes('f_dashboard')) {
    controlPanel.push({
      title: dashboardRoute.title,
      path: dashboardRoute.path,
      icon: <DonutLargeIcon />,
    });
  }

  controlPanel.push({
    title: 'sidebar.tax-details.title',
    icon: <RecentActorsIcon />,
    path: personalDetailsRoute.path,
    children: taxDetailsPanel,
  });

  if (features.includes('f_expense_list')) {
    controlPanel.push({
      title: expensesRoute.title,
      path: expensesRoute.path,
      icon: <LocalAtmIcon />,
    });
  }

  if (features.includes('f_invoice_list')) {
    controlPanel.push({
      title: 'sidebar.invoices.title',
      icon: <ReceiptIcon />,
      path: invoicesRoute.path,
      children: invoicePanel,
    });
  }

  if (features.includes('f_tax_prediction')) {
    controlPanel.push({
      title: 'sidebar.taxes.title',
      icon: <ShowChartIcon />,
      path: taxesIrsRoute.path,
      children: taxesPanel,
    });

    taxesPanel.push({
      title: taxesIrsRoute.title,
      path: taxesIrsRoute.path,
      icon: <MonetizationOnIcon />,
      subject: 'IRS',
    });

    taxesPanel.push({
      title: taxesVatRoute.title,
      path: taxesVatRoute.path,
      icon: <ReceiptIcon />,
      subject: 'VAT',
    });

    taxesPanel.push({
      title: taxesSocialSecurityRoute.title,
      path: taxesSocialSecurityRoute.path,
      icon: <AccessibilityIcon />,
      subject: 'SS',
    });
  }

  if (features.includes('f_calendar')) {
    controlPanel.push({
      title: calendarRoute.title,
      path: calendarRoute.path,
      icon: <CalendarTodayIcon />,
    });
  }

  controlPanel.push({
    title: 'sidebar.simulators.title',
    icon: <AssessmentIcon />,
    path: simulatorsRoute.path,
    children: simulatorsPanel,
  });

  simulatorsPanel.push({
    title: simulatorsRoute.title,
    path: simulatorsRoute.path,
    icon: <ShowChartIcon />,
  });

  controlPanel.push({
    title: assistantRoute.title,
    path: assistantRoute.path,
    icon: <AnimatedBotIcon />,
    beta: true,
  });

  const other: NavItem[] = [];

  other.push({
    title: integrationsRoute.title,
    path: integrationsRoute.path,
    icon: <DashboardIcon />,
    subject: 'Integration',
  });

  // other.push({
  //   title: partnersRoute.title,
  //   path: partnersRoute.path,
  //   icon: <PeopleAltIcon />,
  // });

  other.push({
    title: blogLink.title,
    path: blogLink.href,
    icon: <MenuBookOutlinedIcon />,
    isExternal: true,
  });

  other.push({
    title: legalLink.title,
    path: legalLink.href,
    icon: <DescriptionIcon />,
    isExternal: true,
  });

  return [
    {
      title: 'sidebar.control-panel',
      children: controlPanel,
    },
    {
      title: 'sidebar.other',
      children: other,
    },
  ];
};

export default function Sidebar() {
  const { data } = useGetProfileQuery();
  const { t } = useTranslation('common');

  const sidebar = createSidebar((data?.plan.features as FeatureType[] | undefined) ?? []);

  return (
    <>
      <NavListSection divider={true} padding="normal">
        <NavAppTitle />
      </NavListSection>
      <NavListSection divider={true}>
        <NavUser />
      </NavListSection>
      {sidebar.map((section, i) => (
        <NavListSection key={section.title} divider={i < sidebar.length - 1} title={t(section.title)}>
          {section.children.map((item, i) => (
            <NavListItem key={item.title} item={item} />
          ))}
        </NavListSection>
      ))}
    </>
  );
}
