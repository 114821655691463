import { yupResolver } from '@hookform/resolvers/yup';
import { DialogContentText, FormControl } from '@material-ui/core';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { usePortalAuthLoginMutation } from '../../redux/activity-provider/activity-provider.api';
import useNotifications from '../../redux/notifications/notifications.hooks';
import usePortalLoginDialog from '../../redux/portal-login-dialog/portal-login-dialog.hooks';
import DialogForm from '../forms/dialog-form';
import FormTextField from '../forms/form-text-field';

interface PortalLoginDialogForm {
  password: string;
}

export default function PortalLoginDialog() {
  const { t } = useTranslation('common');
  const methods = useForm<PortalLoginDialogForm>({
    resolver: yupResolver(
      yup
        .object({
          password: yup.string().required(),
        })
        .required(),
    ),
  });
  const { isOpen, confirm, cancel } = usePortalLoginDialog();
  const { show } = useNotifications();
  const [portalAuthLogin] = usePortalAuthLoginMutation();

  useEffect(() => {
    methods.reset();
  }, [isOpen, methods]);

  const handleCancel = () => {
    cancel();
  };

  const handleConfirm = async (data: PortalLoginDialogForm) => {
    try {
      await portalAuthLogin({ password: data.password });
      confirm();
    } catch (error) {
      show({
        severity: 'error',
        message: t('portal-login-dialog.error-invalid-credentials'),
      });
    }
  };

  return (
    <DialogForm
      title={t('portal-login-dialog.title')}
      submitName={t('common.button-authenticate')}
      methods={methods}
      open={isOpen}
      onClose={handleCancel}
      onSubmit={handleConfirm}
    >
      <DialogContentText>{t('portal-login-dialog.message-info')}</DialogContentText>
      <FormControl fullWidth margin="normal">
        <FormTextField
          name="password"
          type="password"
          label={t('portal-login-dialog.label-finance-password')}
          autoFocus
        />
      </FormControl>
    </DialogForm>
  );
}
