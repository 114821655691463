import Lottie, { LottieOptions } from 'lottie-react';

export type LottieIconProps = LottieOptions;

export default function LottieIcon(props: LottieIconProps) {
  return (
    <Lottie
      {...props}
      style={{
        width: '24px',
        height: '24px',
      }}
    />
  );
}
