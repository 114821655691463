import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import useConfirmationDialog from '../../redux/confirmation-dialog/confirmation-dialog.hooks';

export default function ConfirmationDialog() {
  const { t } = useTranslation('common');
  const { isOpen, title, message, confirm, cancel } = useConfirmationDialog();

  const handleCancel = () => {
    cancel();
  };

  const handleConfirm = () => {
    confirm();
  };

  return (
    <Dialog open={isOpen}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {t('common.button-cancel')}
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          {t('common.button-ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
