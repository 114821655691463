import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import constant from './translations/constant.json';
import common_en from './translations/en/common.json';
import common_pt from './translations/pt/common.json';

i18n
  //.use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false,
    },
    fallbackLng: 'pt-PT',
    resources: {
      en: {
        common: common_en,
        constant,
      },
      pt: {
        common: common_pt,
        constant,
      },
    },
  });

export default i18n;
