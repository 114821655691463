import Link from '@material-ui/core/Link';
import React from 'react';
import { useHref } from 'react-router-dom';

interface HyperlinkProps {
  path: string;
  children?: string | React.ReactNode;
}

export default React.forwardRef<unknown, HyperlinkProps>(({ path, children, ...rest }, ref) => {
  const href = useHref(path);

  return (
    <Link {...rest} href={href}>
      {children}
    </Link>
  );
});
