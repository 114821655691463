export enum LocationEnum {
  Azores = 'PT.AC',
  Madeira = 'PT.MA',
  Portugal = 'PT',
  Other = 'Other',
}

export type ActivityLocation = Extract<
  LocationEnum,
  LocationEnum.Azores | LocationEnum.Madeira | LocationEnum.Portugal
>;

export const ActivityLocation = {
  Azores: LocationEnum.Azores,
  Madeira: LocationEnum.Madeira,
  Portugal: LocationEnum.Portugal,
} as const;

export type ClientLocation = Extract<
  LocationEnum,
  LocationEnum.Azores | LocationEnum.Madeira | LocationEnum.Portugal | LocationEnum.Other
>;

export const ClientLocation = {
  Azores: LocationEnum.Azores,
  Madeira: LocationEnum.Madeira,
  Portugal: LocationEnum.Portugal,
  Other: LocationEnum.Other,
} as const;

export enum ClientType {
  Company = 'Company',
  Individual = 'Individual',
}

export interface TaxesSimulationRequest {
  yearlyIncome: number;
  activityLocation: ActivityLocation;
  activityYears?: number;
  clientLocation: ClientLocation;
  clientType: ClientType;
}

export interface TaxesSimulationResponse {
  items: TaxesSimulationItem[];
}

export interface TaxesSimulationItem {
  year: number;
  grossIncome: number;
  totalReceived: number;
  netIncome: number;
  totalTax: number;
  totalTaxExclVat: number;
  totalIncomeTax: number;
  totalVatTax: number;
  monthlyVatTax: number;
  quarterlyVatTax: number;
  totalSocialSecurity: number;
  monthlySocialSecurity: number;
}
