import { lazy } from 'react';
import ForgotPasswordPage from '../pages/forgot-password/forgot-password.page';
import LoginPage from '../pages/login/login.page';
import PasswordResetPage from '../pages/password-reset/password-reset.page';
import RegistrationPage from '../pages/registration/registration.page';
import VerifyPage from '../pages/verify/verify.page';
import { NavExternalLink, NavRoute } from './nav.interfaces';

const AccountPage = lazy(() => import('../pages/account/account.page'));
const CalendarPage = lazy(() => import('../pages/calendar/calendar.page'));
const DashboardPage = lazy(() => import('../pages/dashboard/dashboard.page'));
const ExpensesPage = lazy(() => import('../pages/expenses/expenses.page'));
const InvoicesPage = lazy(() => import('../pages/invoices/invoices.page'));
const NewInvoicePage = lazy(() => import('../pages/new-invoice/new-invoice.page'));
const IntegrationsPage = lazy(() => import('../pages/integrations/integrations.page'));
const PartnersPage = lazy(() => import('../pages/partners/partners.page'));
const GreenReceiptsReceiptPage = lazy(() => import('../pages/green-receipts-receipt/green-receipts-receipt.page'));
const TaxesIrsPage = lazy(() => import('../pages/taxes/taxes-irs.page'));
const TaxesVatPage = lazy(() => import('../pages/taxes/taxes-vat.page'));
const TaxesSocialSecurityPage = lazy(() => import('../pages/taxes/taxes-social-security.page'));
const AssistantPage = lazy(() => import('../pages/assistant/assistant.page'));
const TaxesSimulatorPage = lazy(() => import('../pages/simulators/taxes/taxes-simulator.page'));
const BusinessDetailsPage = lazy(() => import('../pages/tax-details/business-details.page'));
const EmploymentDetailsPage = lazy(() => import('../pages/tax-details/employment-details.page'));
const PersonalDetailsPage = lazy(() => import('../pages/tax-details/personal-details.page'));

export const ROUTE_REGISTRATION = '/registration';
export const ROUTE_LOGIN = '/login';
export const ROUTE_FORGOT_PASSWORD = '/forgot-password';
export const ROUTE_PASSWORD_RESET = '/password-reset';
export const ROUTE_VERIFY = '/verify';
export const ROUTE_HOME = '/';
export const ROUTE_ASSISTANT = '/assistant';
export const ROUTE_EXPENSES = '/expenses';
export const ROUTE_INVOICES = '/invoices';
export const ROUTE_AUTOMATED_INVOICES = '/automated-invoices';
export const ROUTE_NEW_INVOICE = '/new-invoice';
export const ROUTE_TAXES_IRS = '/taxes/irs';
export const ROUTE_TAXES_VAT = '/taxes/vat';
export const ROUTE_TAXES_SOCIAL_SECURITY = '/taxes/social-security';
export const ROUTE_ACCOUNT = '/account';
export const ROUTE_INTEGRATIONS = '/integrations';
export const ROUTE_CALENDAR = '/calendar';
export const ROUTE_HELP = '/help';
export const ROUTE_BUSINESS_DETAILS = '/tax-details/business';
export const ROUTE_EMPLOYMENT_DETAILS = '/tax-details/employment';
export const ROUTE_PERSONAL_DETAILS = '/tax-details/personal';
export const ROUTE_PARTNERS = '/partners';
export const ROUTE_GREEN_RECEIPTS_RECEIPT = '/invoices/green-receipts';
export const ROUTE_SIMULATORS_TAXES = '/simulators/taxes';
export const LINK_TERMS = 'https://jupiterapp.pt/termos-e-condicoes/';
export const LINK_BLOG = 'https://jupiterapp.pt/blog/';

export type AccountTab = 'billing' | 'profile';

export const loginRoute: NavRoute = {
  title: 'Login',
  path: ROUTE_LOGIN,
  component: <LoginPage />,
};

export const registrationRoute: NavRoute = {
  title: 'Registo',
  path: ROUTE_REGISTRATION,
  component: <RegistrationPage />,
};

export const verifyRoute: NavRoute = {
  title: 'Verify',
  path: ROUTE_VERIFY,
  component: <VerifyPage />,
};

export const forgotPasswordRoute: NavRoute = {
  title: 'Recuperação de Conta',
  path: ROUTE_FORGOT_PASSWORD,
  component: <ForgotPasswordPage />,
};

export const resetPasswordRoute: NavRoute = {
  title: 'Alteração de Palavra-Passe',
  path: ROUTE_PASSWORD_RESET,
  component: <PasswordResetPage />,
};

export const assistantRoute: NavRoute = {
  title: 'sidebar.assistant',
  path: ROUTE_ASSISTANT,
  component: <AssistantPage />,
};

export const dashboardRoute: NavRoute = {
  title: 'sidebar.dashboard',
  path: ROUTE_HOME,
  component: <DashboardPage />,
};

export const expensesRoute: NavRoute = {
  title: 'sidebar.expenses',
  path: ROUTE_EXPENSES,
  component: <ExpensesPage />,
};

export const invoicesRoute: NavRoute = {
  title: 'sidebar.invoices.list',
  path: ROUTE_INVOICES,
  component: <InvoicesPage />,
};

export const newInvoiceRoute: NavRoute = {
  title: 'sidebar.invoices.new',
  path: ROUTE_NEW_INVOICE,
  component: <NewInvoicePage />,
};

export const taxesIrsRoute: NavRoute = {
  title: 'sidebar.taxes.irs',
  path: ROUTE_TAXES_IRS,
  component: <TaxesIrsPage />,
};

export const taxesVatRoute: NavRoute = {
  title: 'sidebar.taxes.vat',
  path: ROUTE_TAXES_VAT,
  component: <TaxesVatPage />,
};

export const taxesSocialSecurityRoute: NavRoute = {
  title: 'sidebar.taxes.ss',
  path: ROUTE_TAXES_SOCIAL_SECURITY,
  component: <TaxesSocialSecurityPage />,
};

export const accountRoute: NavRoute = {
  title: 'sidebar.account',
  path: ROUTE_ACCOUNT,
  component: <AccountPage />,
  showAlways: true,
};

export const businessDetailsRoute: NavRoute = {
  title: 'sidebar.tax-details.business',
  path: ROUTE_BUSINESS_DETAILS,
  component: <BusinessDetailsPage />,
};

export const employmentDetailsRoute: NavRoute = {
  title: 'sidebar.tax-details.employment',
  path: ROUTE_EMPLOYMENT_DETAILS,
  component: <EmploymentDetailsPage />,
};

export const personalDetailsRoute: NavRoute = {
  title: 'sidebar.tax-details.personal',
  path: ROUTE_PERSONAL_DETAILS,
  component: <PersonalDetailsPage />,
};

export const integrationsRoute: NavRoute = {
  title: 'sidebar.integrations',
  path: ROUTE_INTEGRATIONS,
  component: <IntegrationsPage />,
};

export const calendarRoute: NavRoute = {
  title: 'sidebar.calendar',
  path: ROUTE_CALENDAR,
  component: <CalendarPage />,
};

export const partnersRoute: NavRoute = {
  title: 'sidebar.partners',
  path: ROUTE_PARTNERS,
  component: <PartnersPage />,
};

export const simulatorsRoute: NavRoute = {
  title: 'sidebar.simulators.taxes',
  path: ROUTE_SIMULATORS_TAXES,
  component: <TaxesSimulatorPage />,
};

export const blogLink: NavExternalLink = {
  title: 'sidebar.blog',
  href: LINK_BLOG,
};

export const legalLink: NavExternalLink = {
  title: 'sidebar.terms-and-conditions',
  href: LINK_TERMS,
};

export const greenReceiptsReceiptRoute: NavRoute = {
  title: 'Emitir Recibo',
  path: ROUTE_GREEN_RECEIPTS_RECEIPT,
  component: <GreenReceiptsReceiptPage />,
};

export const privateRoutes: NavRoute[] = [
  assistantRoute,
  dashboardRoute,
  expensesRoute,
  invoicesRoute,
  taxesIrsRoute,
  taxesVatRoute,
  taxesSocialSecurityRoute,
  accountRoute,
  integrationsRoute,
  calendarRoute,
  partnersRoute,
  newInvoiceRoute,
  greenReceiptsReceiptRoute,
  simulatorsRoute,
  personalDetailsRoute,
  businessDetailsRoute,
  employmentDetailsRoute,
];

export const publicRoutes: NavRoute[] = [
  loginRoute,
  registrationRoute,
  verifyRoute,
  forgotPasswordRoute,
  resetPasswordRoute,
];
